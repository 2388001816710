import React from "react";
import { dummyPlanData } from "./PlanTile";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
const ExpandedPlanDetails = ({ planDetails }) => {
  if (!planDetails) return;
  const { user_plan_pause, user_plan_extension, start_date, end_date } =
    planDetails;

  const planPauseAndExtension = [...user_plan_pause, ...user_plan_extension];
  const paymentDetails = planDetails.payment || {
    description: "",
    admin_notes: "",
    user_notes: "",
  };
  const planDescription = planDetails.plan.description;
  const internalNotes = paymentDetails.admin_notes;
  const customerFacingNotes = paymentDetails.user_notes;

  return (
    <div
      style={{
        fontSize: "0.75rem",
        padding: "0.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        fontSize: "0.9rem",
        color: "#333",
      }}
    >
      <p style={{ fontSize: "1rem", fontWeight: 500 }}>Plan Journey</p>
      <div style={{ display: "flex", gap: "0.5rem", position: "relative" }}>
        <Line type={"solid"} />
        <div style={{ fontSize: "0.9rem" }}>Start: {start_date}</div>
        <div style={{ height: "40px" }}></div>
      </div>

      {planPauseAndExtension.map((plan, index) => {
        const isPause = plan.type === "pause";
        const isLast = index === planPauseAndExtension.length - 1;
        const isCurrent = plan?.active_journey;
        return (
          <div
            key={index}
            style={{ display: "flex", gap: "0.5rem", position: "relative" }}
          >
            <Line
              isCurrent={isCurrent}
              type={isPause ? "dashed" : "solid"}
              isLast={isLast}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="capitalize">
                {plan.type} start: {plan.start_date}
              </p>
              <p>{plan.description}</p>
              <p>
                {plan.type} end: {plan.end_date}
              </p>
            </div>

            {/* <p>{plan.created_by}</p> */}
          </div>
        );
      })}
      <div style={{ display: "flex", gap: "0.5rem", position: "relative" }}>
        <Line type={"solid"} />
        <div>
          <div style={{ height: "40px" }}></div>
          <div>End: {end_date}</div>
        </div>
      </div>
      <div className="my-2" style={{ fontWeight: 500, fontSize: "1rem" }}>
        Plan Details
      </div>
      <PlanDescriptionAndNotes
        planDescription={planDescription}
        customerFacingNotes={customerFacingNotes}
        internalNotes={internalNotes}
      />
      <ServiceDetails planItems={planDetails.user_plan_items} />
    </div>
  );
};

export default ExpandedPlanDetails;

const PlanDescriptionAndNotes = ({
  planDescription,
  customerFacingNotes,
  internalNotes,
}) => {
  const data = [
    { title: "Plan Description", content: planDescription },
    { title: "Customer Facing Notes", content: customerFacingNotes },
    { title: "Internal Notes", content: internalNotes },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
      }}
    >
      {data.map(({ title, content }, index) => {
        return (
          <div
            key={index}
            style={{
              fontSize: "0.75rem",
              backgroundColor: "#F1F5FF",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              color: "#333",
            }}
          >
            <p style={{ fontSize: "0.75rem", fontWeight: "500" }}>{title}</p>
            <p style={{ fontSize: "0.75rem" }}>{content}</p>
          </div>
        );
      })}
    </div>
  );
};

const ServiceDetails = ({ planItems }) => {
  const columns = [
    { title: "Sl. no", render: ([_, index]) => <div>{index}</div> },

    {
      title: "Service",
      render: ([record]) => (
        <div className="text-sm">
          {record?.product.product_name} ({record?.quantity})
        </div>
      ),
    },

    {
      title: "Quantity",
      render: ([record]) => <div>{record.quantity || ""}</div>,
    },
  ];
  return (
    <div
      style={{
        fontSize: "0.75rem",
        padding: "0.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        width: "50%",
      }}
    >
      <div>Service Details</div>
      <TableContainer component={Paper} className="overflow-x-auto">
        <Table className="min-w-full">
          <TableHead style={{ fontWeight: "500" }}>
            <TableRow className="bg-gray-100">
              {columns.map((column, index) => (
                <TableCell key={index} className="font-medium text-gray-700">
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {planItems.map((plan, planIdx) => (
              <TableRow
                key={plan.id}
                // onClick={() => handleRowClick(plan)}
                className="hover:bg-gray-100 cursor-pointer cursor transition-colors duration-150"
                style={{ transition: "background-color 0.15s ease-in-out" }}
              >
                {columns.map((column, index) => (
                  <TableCell key={index}>
                    {column.render
                      ? column.render([plan, planIdx + 1])
                      : plan[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
const getLineStyle = (type, isCurrent) => {
  if (type === "dashed") {
    return isCurrent ? "2px solid #243B86" : "2px dashed #D1DCFF";
  }
  return isCurrent ? "2px dashed #243B86" : "2px solid #D1DCFF";
};
const getLineColor = (type, isCurrent) => {
  if (type === "dashed") {
    return isCurrent ? "#243B86" : "#D1DCFF";
  }
  return isCurrent ? "#243B86" : "#D1DCFF";
};
const Line = ({ type, isCurrent, isLast }) => {
  return (
    <div
      style={{
        // position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "2px",
        height: "auto",
        borderRight: isCurrent ? "2px solid #243B86" : "2px dashed #D1DCFF", // Solid line styling
        marginTop: "4px",
      }}
    >
      <svg
        width="8"
        height="8"
        style={{ marginBottom: "4px", position: "absolute", top: 0 }}
      >
        <circle cx="5" cy="4" r="3" fill={getLineColor(type, isCurrent)} />
      </svg>
      {isLast ? (
        <svg
          width="12"
          height="8"
          style={{ marginBottom: "4px", position: "absolute", bottom: -10 }}
        >
          <polygon points="2,0 12,0 7,8" fill={getLineColor(type, isCurrent)} />
        </svg>
      ) : (
        <svg
          width="8"
          height="8"
          style={{ marginBottom: "4px", position: "absolute", bottom: -10 }}
        >
          <circle cx="5" cy="4" r="3" fill={getLineColor(type, isCurrent)} />
        </svg>
      )}
    </div>
  );
};
