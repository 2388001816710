import PausePlanForm from "./PausePlanForm";
import PlanTile, { dummyPlanData } from "./PlanTile";

const Plans2Temp = () => {
  return (
    <div className="container">
      {/* <PlanTile /> */}
      <PausePlanForm planData={dummyPlanData} />
    </div>
  );
};
export default Plans2Temp;
