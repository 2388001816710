import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import { WhatsApp, MoreVert, Call } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "whealth-core-web/components/Helper";

const calculateWeightChange = (patient) => {
  const currentWeight = parseFloat(patient.health_matrix.current_weight);
  const startingWeight = parseFloat(patient.starting_weight);
  if (isNaN(currentWeight) || isNaN(startingWeight)) return "";
  return (currentWeight - startingWeight).toFixed(1);
};

const calculateTimeSinceLastWeightLog = (patient) => {
  const lastWeightLog = new Date(patient.last_weight_log_activity);
  const now = new Date();
  const diffHours = Math.floor((now - lastWeightLog) / (1000 * 60 * 60));
  return `${diffHours} Hours ago`;
};

const transformPatientData = (patient) => ({
  ...patient,
  language: patient.health_matrix.language || "",
  personality: patient.health_matrix.personality || patient.personality || "",
  program: patient.health_matrix.program || patient.program || "",
  weight: patient.weight || "",
  weight_change: calculateWeightChange(patient),
  weight_change_time: calculateTimeSinceLastWeightLog(patient),
  plan_status: patient.onboarding_status || "",
  call_status: patient.health_matrix.call_status || "N/A",
  lab_status: patient.health_matrix.lab_status || "",
});

const PatientListing = ({ patients }) => {
  const navigate = useNavigate();

  const handleRowClick = (record) => {
    navigate(`/patient/${record.id}/dashboard`, { state: { data: record } });
  };

  const columns = [
    {
      title: "Patient",
      render: (record) => (
        <div
          className="flex items-center space-x-3"
          style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}
        >
          <Avatar
            src={record.profile_url}
            className="flex-shrink-0"
            style={{ flexShrink: 0 }}
          >
            {record.full_name.charAt(0)}
          </Avatar>
          <div
            className="flex-grow min-w-0"
            style={{ flexGrow: 1, minWidth: 0 }}
          >
            <div
              className="font-medium truncate"
              style={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.full_name}
              <span style={{ fontSize: "10px", marginLeft: "4px" }}>
                {(record.age || record.gender) && " ("}
                {record.age && `${record.age}yrs`}
                {record.age && record.gender && ", "}
                {record.gender &&
                  (record.gender.toLowerCase() === "male"
                    ? "M"
                    : record.gender.toLowerCase() === "female"
                    ? "F"
                    : "O")}
                {(record.age || record.gender) && ")"}
              </span>
            </div>

            <div
              className="text-xs text-gray-500 flex items-center"
              style={{
                fontSize: "0.75rem",
                color: "#6B7280",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Call
                className="mr-1 flex-shrink-0"
                style={{ marginRight: "0.25rem", flexShrink: 0 }}
                fontSize="small"
              />
              <span
                className="truncate"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {record.phone}
              </span>
            </div>
            <div
              style={{
                fontSize: "0.75rem",
                color: "#6B7280",
                display: "flex",
                alignItems: "center",
              }}
            >
              {record?.created_at && (
                <div className="bottomTextView">
                  {"Joined on"} : {dateFormat(record?.created_at)}
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    { title: "Language", field: "language" },
    // { title: 'City', field: 'city' },
    { title: "Personality", field: "personality" },
    { title: "Program", field: "program" },
    {
      title: "Weight",
      render: (record) => (
        <div>
          {record.weight}
          {record.health_matrix.current_weight &&
            ` (${record.health_matrix.current_weight})`}
          {record.weight_change !== "N/A" &&
            parseFloat(record.weight_change) !== 0 && (
              <div
                className={`text-xs ${
                  parseFloat(record.weight_change) > 0
                    ? "text-green-500"
                    : "text-red-500"
                }`}
                style={{
                  fontSize: "0.75rem",
                  color:
                    parseFloat(record.weight_change) < 0
                      ? "#10B981"
                      : parseFloat(record.weight_change) > 0
                      ? "#EF4444"
                      : "",
                }}
              >
                {parseFloat(record.weight_change) > 0
                  ? "+"
                  : parseFloat(record.weight_change) < 0
                  ? "-"
                  : ""}
                {record.weight_change &&
                  `${Math.abs(parseFloat(record.weight_change))} Kgs`}
              </div>
            )}
          <div
            className="text-xs text-gray-500"
            style={{
              fontSize: "0.75rem",
              color: "#6B7280",
            }}
          >
            {record.weight_change_time}
          </div>
        </div>
      ),
    },
    {
      title: "Team",
      render: (record) => (
        <div className="text-sm">
          {record.admins.slice(0, 2).map((admin, index) => (
            <div key={index}>
              {admin.full_name} ({admin.role})
            </div>
          ))}
          {record.admins.length > 2 && (
            <a href="#" className="text-blue-500">
              +{record.admins.length - 2} More
            </a>
          )}
        </div>
      ),
    },
    {
      title: "Plan Status",
      render: (record) => {
        const isEnrolled = record.plan_status.toLowerCase() === "enrolled";
        return (
          <Chip
            label={record.plan_status || "-"}
            size="small"
            sx={{
              backgroundColor: isEnrolled ? "#F0FDF4" : "#EBF0FF",
              color: isEnrolled ? "#166534" : "#243B86",
              borderRadius: "16px",
              padding: "2px 8px",
              "& .MuiChip-label": {
                padding: 0,
              },
            }}
          />
        );
      },
    },
    // { title: 'Call Status', field: 'call_status' },
    {
      title: "Lab Status",
      render: (record) => (
        <Tooltip title={`Orange ${new Date().toLocaleDateString()}`}>
          <span className="text-orange-500">{record.lab_status || "-"}</span>
        </Tooltip>
      ),
    },
    // {
    //   title: '',
    //   render: () => (
    //     <IconButton size='small'>
    //       <MoreVert className='text-gray-400' />
    //     </IconButton>
    //   ),
    // },
  ];

  const transformedPatients = patients.map(transformPatientData);

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <TableContainer component={Paper} className="overflow-x-auto">
        <Table className="min-w-full">
          <TableHead style={{ background: "#ebf0ff", fontWeight: "500" }}>
            <TableRow className="bg-gray-100">
              {columns.map((column, index) => (
                <TableCell key={index} className="font-medium text-gray-700">
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedPatients.map((patient) => (
              <TableRow
                key={patient.id}
                onClick={() => handleRowClick(patient)}
                className="hover:bg-gray-100 cursor-pointer cursor transition-colors duration-150"
                style={{ transition: "background-color 0.15s ease-in-out" }}
              >
                {columns.map((column, index) => (
                  <TableCell key={index}>
                    {column.render
                      ? column.render(patient)
                      : patient[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientListing;
