import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  blue_copy,
  blue_download,
  blue_eye,
  blue_recyclebin_delete,
  blueEdit,
  blueLinkUrl,
  bluePublish,
  linkUrl,
} from "res/images";
import CommonToolTip from "../CommonToolTip";
import { dateFormat, profileImage, teamRoles, timeFormat } from "../Helper";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import { tickIcon } from "whealth-core-web/assets/images";
import DietPlanCollapse from "./DietPlanCollapse";
import TextWithFormatting from "./TextWithFormatting";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";

function DietplanList(props) {
  const {
    dietplanList,
    dietPlanDelete,
    statusPublish,
    getPdf,
    openModalPdf,
    openModalPdfDownload,
    handlePublishAndPrint,
    closeModalPdf,
    pdfModal,
    pdfUrl,
    dietPlanClone,
    closeModalPdfDownload,
    pdfModalDownload,
    copyDeepLink,
    currentPage,
  } = props;
  const [showFullContent, setShowFullContent] = useState(false);
  const [modelItemId, setModalItemId] = useState("");
  const [modelItemIndex, setModalItemIndex] = useState("");
  const adminData = useSelector((state) => state?.sliceReducer?.adminData);
  const { id } = useParams();
  const navigate = useNavigate();

  const formatTime = (timeValue) => {
    if (timeValue === null || timeValue === undefined || timeValue === "") {
      return { formatted: "", value: "" };
    }

    const timeStr = timeValue.toString().padStart(4, "0");

    if (timeStr.length !== 4) {
      throw new Error("Invalid time format. Expected HHMM format.");
    }

    let hours = parseInt(timeStr.substring(0, 2), 10);
    let minutes = timeStr.substring(2, 4);

    if (isNaN(hours) || isNaN(parseInt(minutes, 10))) {
      throw new Error(
        "Invalid time format. Hours and minutes should be numeric."
      );
    }

    // Handle times beyond 24 hours
    hours = hours % 24;

    let period = hours < 12 ? "AM" : "PM";
    let displayHours = hours % 12 || 12;

    let formattedHours = displayHours.toString().padStart(2, "0");
    let startFormatted = `${formattedHours}:${minutes} ${period}`;

    let nextMinutes = (parseInt(minutes, 10) + 30) % 60;
    let nextHours = hours + (parseInt(minutes, 10) + 30 >= 60 ? 1 : 0);

    nextHours = nextHours % 24;
    let nextPeriod = nextHours < 12 ? "AM" : "PM";
    let nextDisplayHours = nextHours % 12 || 12;
    let nextFormattedHours = nextDisplayHours.toString().padStart(2, "0");
    let endFormatted = `${nextFormattedHours}:${nextMinutes
      .toString()
      .padStart(2, "0")} ${nextPeriod}`;

    return {
      startFormatted: startFormatted,
      endFormatted: endFormatted,
      value: `${timeStr}`,
    };
  };

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const trimContent = (content, maxLength) => {
    return content && content.length > maxLength
      ? `${content.substring(0, maxLength)}...`
      : content;
  };

  const formatDate = (date, options = { day: "2-digit", month: "short" }) => {
    return date.toLocaleDateString("en-GB", options);
  };

  const convertDays = (input) => {
    const dayShortNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return input
      .split(",")
      .map((num) => dayShortNames[parseInt(num)])
      .join(", ");
  };

  const groupByTwins = (items) => {
    return items.reduce((acc, item) => {
      (acc[item.twins] = acc[item.twins] || []).push(item);
      return acc;
    }, {});
  };

  const renderDietPlanCard = (diet_title, start_date, end_date, week_days) => {
    return (
      <div
        style={{
          backgroundColor: "#F1F5FF",
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 10,
          padding: 10,
          borderRadius: 6,
        }}
      >
        <div
          style={{
            fontWeight: "500",
            color: "#333333",
            fontSize: 14,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          {diet_title}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 12,
            color: "#656364",
          }}
        >
          {start_date && end_date && (
            <div>{`${formatDate(new Date(start_date))} -  ${formatDate(
              new Date(end_date)
            )}`}</div>
          )}
          {start_date && end_date && (
            <div
              style={{
                backgroundColor: "#333",
                width: 1,
                marginRight: 10,
                marginLeft: 10,
              }}
            ></div>
          )}
          <div>{convertDays(week_days)}</div>
        </div>
      </div>
    );
  };

  const renderDayPartCard = (dayPartItem, index) => {
    const groupedFoodItems = groupByTwins(dayPartItem.diet_plan_food_items);
    const keys = Object.keys(groupedFoodItems);
    const formattedTimeInterval = formatTime(dayPartItem.notification_time);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 20,
          marginRight: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginRight: 10,
          }}
        >
          <div style={{ fontWeight: 600, color: "#333333" }}>
            {dayPartItem.day_part.title}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "10px",
              borderColor: "#243B86",
              alignItems: "center",
              justifyContent: "center",
              padding: 5,
              color: "#243B86",
              margin: 10,
            }}
          >
            <p style={{ lineHeight: 1 }}>
              {formattedTimeInterval.startFormatted}
            </p>
            <div style={{ lineHeight: 1 }}>-</div>
            <p style={{ lineHeight: 1 }}>
              {formattedTimeInterval.endFormatted}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "10px",
            borderColor: "#D9D9D9",
            width: "80%",
            flexDirection: "column",
            paddingBottom: 10,
          }}
        >
          {keys.map((key) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 15,
                paddingLeft: 10,
              }}
            >
              <img
                height={15}
                src={tickIcon}
                alt="Tick Icon"
                style={{
                  alignSelf: "flex-start",
                  marginRight: 10,
                  marginTop: 5,
                }}
              />
              {groupedFoodItems[key].map((foodItem, index) => {
                return (
                  <React.Fragment>
                    <ddiv
                      key={foodItem.id}
                      style={{
                        display: "flex",
                        //flexGrow: 1,
                        flexDirection: "column",
                        width: "40%",
                      }}
                    >
                      <div>
                        {foodItem.food} {foodItem.quantity}
                      </div>

                      {foodItem.recipe_link && (
                        <a
                          href={foodItem.recipe_link}
                          style={{
                            color: "#243B86",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                        >
                          Recipe Link
                        </a>
                      )}
                    </ddiv>

                    {index < groupedFoodItems[key].length - 1 && (
                      <div
                        style={{
                          display: "flex",
                          // flexGrow: 1,
                          flexDirection: "column",
                          width: "10%",
                        }}
                      >
                        or,
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          ))}
          {dayPartItem.note && (
            <div
              style={{
                borderStyle: "dashed",
                borderTopWidth: 1,
                borderColor: "white",
                borderTopColor: "#D9D9D9",
                width: "100%",
                paddingLeft: 10,
                paddingTop: 5,
                marginTop: 10,
              }}
            >
              <TextWithFormatting text={dayPartItem.note} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDietlist = (item, index) => {
    console.log(item);
    return (
      <div>
        <div
          className="cardDataBorder w-100"
          style={{ borderColor: "#D1DCFF", padding: 0 }}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              background: "#F1F5FF",
              padding: 10,
              paddingRight: 15,
              paddingBottom: 0,
            }}
          >
            <div className="headingMilestone d-flex justify-content-between">
              <div style={{ display: "flex", height: 20 }}>
                <div
                  className={`capitalize  border-right-0`}
                  style={{ color: "#243B86", alignSelf: "center" }}
                >
                  <b>{item.status}</b>
                </div>
                <div
                  style={{
                    backgroundColor: "#656364",
                    width: 1,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                ></div>
                <div style={{ color: "#656364", alignSelf: "center" }}>
                  {formatDate(
                    new Date(
                      item.status === "draft"
                        ? item.updated_at
                        : item.published_at
                    ),
                    {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }
                  )}{" "}
                  ,{" "}
                  {timeFormat(
                    item.status === "draft"
                      ? item.updated_at
                      : item.published_at
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "#333",
                    width: 1,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                ></div>
                <div
                  className={`capitalize  border-right-0`}
                  style={{ alignSelf: "center" }}
                >
                  {item.created_by}
                </div>
              </div>
            </div>

            <div
              className="memberShipDetails"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {item.status == "draft" && (
                <div
                  className="mb-1 ms-2 pointer"
                  onClick={() => {
                    let windoConfirm = window.confirm(
                      "Are you sure you want to delete this Diet Plan"
                    );
                    if (windoConfirm) {
                      dietPlanDelete(item.id);
                    }
                  }}
                  style={{
                    display: "flex",
                    backgroundColor: "#D1DCFF",
                    borderRadius: 5,
                    height: 28,
                    width: 28,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="pt-1">
                    <CommonToolTip
                      placement={"top"}
                      item={
                        <img
                          src={blue_recyclebin_delete}
                          style={{ height: 25, width: 21 }}
                        />
                      }
                      showValue={"Delete"}
                    />
                  </div>
                </div>
              )}

              {item.status == "published" && (
                <div
                  className="mb-1 ms-2 pointer"
                  style={{
                    display: "flex",
                    backgroundColor: "#D1DCFF",
                    borderRadius: 5,
                    height: 28,
                    width: 28,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CommonToolTip
                    placement={"top"}
                    item={
                      <img
                        onClick={() => {
                          copyDeepLink(item.id);
                        }}
                        src={blueLinkUrl}
                        alt="link"
                        className="icon"
                        style={{ cursor: "pointer" }}
                      />
                    }
                    showValue={"Deeplink"}
                  />
                </div>
              )}
              <div
                className="mb-1 ms-2 pointer"
                onClick={() => {
                  dietPlanClone(item.id);
                }}
                style={{
                  display: "flex",
                  backgroundColor: "#D1DCFF",
                  borderRadius: 5,
                  height: 28,
                  width: 28,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CommonToolTip
                  placement={"top"}
                  item={<img src={blue_copy} />}
                  showValue={"Duplicate"}
                />
              </div>

              <>
                {item.status == "published" && (
                  <div
                    className="mb-1 ms-2 pointer"
                    onClick={() => {
                      openModalPdfDownload(item, index);
                      setModalItemId(item);
                      setModalItemIndex(index);
                    }}
                    style={{
                      display: "flex",
                      backgroundColor: "#D1DCFF",
                      borderRadius: 5,
                      height: 28,
                      width: 28,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CommonToolTip
                      placement={"top"}
                      item={<img src={blue_download} />}
                      showValue={"Download Pdf"}
                    />
                  </div>
                )}
                {item.status == "draft" && (
                  <div
                    onClick={() => {
                      navigate(`/patient/${id}/dietPlan/${item.id}/edit`);
                    }}
                    className="mb-1 ms-2 pointer"
                    style={{
                      display: "flex",
                      backgroundColor: "#D1DCFF",
                      borderRadius: 5,
                      height: 28,
                      width: 28,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CommonToolTip
                      placement={"top"}
                      item={
                        <img
                          src={blueEdit}
                          className="icon"
                          style={{ cursor: "pointer" }}
                        />
                      }
                      showValue={"Edit"}
                    />
                  </div>
                )}
                {item.status !== "published" && (
                  <>
                    <div
                      onClick={() => {
                        openModalPdf(item);
                      }}
                      className="mb-1 ms-2 pointer"
                      style={{
                        display: "flex",
                        backgroundColor: "#D1DCFF",
                        borderRadius: 5,
                        height: 28,
                        width: 28,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CommonToolTip
                        placement={"top"}
                        item={<img src={blue_eye} />}
                        showValue={"View"}
                      />
                    </div>

                    {/* <CommonToolTip
                      placement={'top'}
                      item={
                        <div
                          className='mb-1 ms-2 pointer'
                          onClick={(e) => {
                            const windowConfirm = window.confirm(`Are you sure,you want to publish Diet Plan?`);
                            if (windowConfirm) {
                              statusPublish(item);
                              mixpanel.track('Publishing of diet plan');
                              mixpanel.people.set({
                                $uuid: adminData.uuid,
                                $name: adminData.full_name,
                                $email: adminData.email,
                                $phone: adminData.phone,
                              });
                            }
                          }}
                        >
                          <img src={bluePublish} style={{ height: 30, width: 30 }} />
                        </div>
                      }
                      showValue={` ${item.status == 'published' ? 'Unpublish' : 'Publish'} `}
                    /> */}
                  </>
                )}
              </>
            </div>
          </div>
          <DietPlanCollapse
            headerComponent={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {item.diet_plans.map((dietPlan, index) => {
                  if (index < 2) {
                    return renderDietPlanCard(
                      dietPlan.title,
                      dietPlan.start_date,
                      dietPlan.end_date,
                      dietPlan.week_days
                    );
                  }
                  if (index === 2) {
                    return (
                      <div
                        style={{
                          color: "#243B86",
                          fontSize: 23,
                          alignSelf: "flex-end",
                          fontWeight: 400,
                          marginBottom: 20,
                        }}
                      >
                        +{item.diet_plans.length - 2}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 15,
                    marginBottom: 15,
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      color: "#333333",
                      marginRight: 10,
                    }}
                  >
                    Weight:{" "}
                    {item.user_weight && (
                      <span style={{ fontWeight: 400, color: "#000000" }}>
                        {`${item.user_weight}`} Kgs
                      </span>
                    )}
                  </div>
                  <div style={{ fontWeight: 600, color: "#333333" }}>
                    BMI:{" "}
                    {item.user_bmi && (
                      <span
                        style={{ fontWeight: 400, color: "#000000" }}
                      >{`${item.user_bmi}`}</span>
                    )}
                  </div>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <div style={{ fontWeight: 600, color: "#333333" }}>
                    Medical Conditions
                  </div>
                  <div style={{ fontWeight: 400, color: "#000000" }}>
                    <TextWithFormatting text={item.user_medical_conditions} />
                  </div>
                </div>
              </div>
              {item.diet_plans.map((diet_plan, index) => {
                const isLast = index === item.diet_plans.length - 1;
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F1F5FF",
                        padding: 10,
                        marginTop: 10,
                        marginBottom: 10,
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 600,
                          color: "#333333",
                          marginRight: 10,
                        }}
                      >
                        {diet_plan.title}
                      </div>
                      {diet_plan.start_date && diet_plan.end_date && (
                        <div>{`${formatDate(
                          new Date(diet_plan.start_date)
                        )} to  ${formatDate(
                          new Date(diet_plan.end_date)
                        )}`}</div>
                      )}
                      {diet_plan.start_date && diet_plan.end_date && (
                        <div
                          style={{
                            backgroundColor: "#333",
                            width: 1,
                            marginRight: 10,
                            marginLeft: 10,
                          }}
                        ></div>
                      )}
                      <div>{convertDays(diet_plan.week_days)}</div>
                    </div>

                    {diet_plan.diet_plan_day_parts.map((dayPartItem, index) => {
                      return renderDayPartCard(dayPartItem, index);
                    })}
                    {isLast && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 15,
                          flexDirection: "column",
                          borderStyle: "dashed",
                          borderTopWidth: 1,
                          borderColor: "white",
                          borderTopColor: "#D9D9D9",
                          width: "100%",
                          paddingTop: 10,
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                      >
                        <div style={{ fontWeight: 600, color: "#333333" }}>
                          Overall Guidelines:
                        </div>
                        <div style={{ fontWeight: 400, color: "#333333" }}>
                          <TextWithFormatting text={item.guidelines} />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </DietPlanCollapse>
        </div>
      </div>
    );
  };

  const renderSectionList = (dietplanList) => {
    let firstArray = [];
    let secondArray = [];
    let switchToSecondArray = false;

    if (currentPage === 1) {
      for (let obj of dietplanList) {
        if (switchToSecondArray) {
          secondArray.push(obj);
        } else {
          firstArray.push(obj);
          if (
            (obj.status === "published" &&
              firstArray.length > 1 &&
              firstArray[firstArray.length - 2].status === "published") ||
            (obj.status === "published" &&
              firstArray.length > 1 &&
              firstArray[firstArray.length - 2].status === "draft")
          ) {
            switchToSecondArray = true;
            secondArray.push(firstArray.pop());
          }
        }
      }
    } else {
      for (let obj of dietplanList) {
        if (switchToSecondArray) {
          secondArray.push(obj);
        } else {
          firstArray.push(obj);
          if (
            firstArray[0].status === "published" ||
            (obj.status === "published" &&
              firstArray.length > 1 &&
              firstArray[firstArray.length - 2].status === "draft")
          ) {
            switchToSecondArray = true;
            secondArray.push(firstArray.pop());
          }
        }
      }
    }
    return (
      <div>
        {firstArray.length > 0 && (
          <div>
            <div style={{ color: "#000000", fontWeight: 500 }}>Active</div>
            {firstArray.map((item, index) => {
              return renderDietlist(item, index);
            })}
          </div>
        )}
        {secondArray.length > 0 && (
          <div style={{ marginTop: 10 }}>
            <div style={{ color: "#000000", fontWeight: 500 }}>History</div>
            {secondArray.map((item, index) => {
              return renderDietlist(item, index);
            })}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <div>{renderSectionList(dietplanList)}</div>

      <Modal
        backdrop="static"
        size="lg"
        open={pdfModalDownload ? pdfModalDownload : pdfModal}
        onClose={pdfModalDownload ? closeModalPdfDownload : closeModalPdf}
      >
        <Modal.Header>
          <Modal.Title>Diet Plan View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={pdfUrl + "#toolbar=0"}
            onContextMenu="return false;"
            width={"100%"}
            height={window.innerHeight}
          />
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <Button
            onClick={(index) => {
              pdfModalDownload
                ? getPdf(modelItemId, modelItemIndex)
                : handlePublishAndPrint();
            }}
            appearance="primary"
          >
            {pdfModalDownload ? "Download" : "Publish"}
          </Button>
          <Button
            onClick={pdfModalDownload ? closeModalPdfDownload : closeModalPdf}
            appearance="defualt"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DietplanList;
