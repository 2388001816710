import { AdminPanelHeader, SideBar, DashBoardHeader } from "components";
import useApiManager from "networking/ApiManager";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { filters, addIconWhite, filter_alt } from "res/images";
import sellPlan from "res/images/sellPlan.png";
import PatientListing from "tailwind-components/PatientListing";
import PlanListing from "tailwind-components/PlanListing";
import CustomerListing from "tailwind-components/CustomerListing";
import {
  CommonAlert,
  Pagination,
  PostHeader,
  Loader,
  EmptyView,
  ShowPatientList,
  FilterModal,
  FilterChips,
} from "whealth-core-web/components";
import SearchBox from "whealth-core-web/components/common/SearchBox";
import { useFilter } from "whealth-core-web/components/FilterContext";
import {
  checkObjectLength,
  genrageFilterParams,
  titlizeString,
} from "whealth-core-web/components/Helper";
import NewPlanDrawer from "whealth-core-web/components/NewPlanDrawer";
import EditPlanDrawer from "whealth-core-web/components/EditPlanDrawer";
import SellPlanDrawer from "whealth-core-web/components/SellPlanDrawer";
import RecordOrderPayment from "whealth-core-web/components/Orders/RecordOrderPayment";
import RecordPaymentDrawer from "whealth-core-web/components/RecordPaymentDrawer";
import { ToastContainer, toast } from "react-toastify";
import { HandCoins } from "lucide-react";

function Tabs({ selected, setSelected, tabs, currentRecordCount, callback }) {
  return (
    <div style={{ display: "flex" }}>
      {tabs?.map((tab) => {
        const isSelected = tab.value === selected.value;
        const count = currentRecordCount[tab.value];
        return (
          <div>
            <div
              onClick={() => {
                setSelected(tab);
                callback();
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginInline: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  fontWeight: 500,
                }}
              >
                {tab.title}
              </div>
              <div
                style={{
                  padding: 2,
                  backgroundColor: isSelected ? "#243B86" : "#EBF0FF",
                  borderRadius: "8px",
                  width: 30,
                  display: "flex",
                  justifyContent: "center",
                  color: isSelected ? "white" : "#243B86",
                }}
              >
                {count}
              </div>
            </div>
            <div
              style={{
                height: 1,
                backgroundColor: isSelected ? "#243B86" : "#EBF0FF",
                width: "100%",
                marginTop: 10,
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
}

export const planKeyMap = {
  published: "Active",
  un_published: "Inactive",
  all: "All",
};

export const customerKeyMap = {
  count: "All",
  paid: "Paid",
  requested: "Not Paid",
};

function Sales() {
  const [invalidateAPIKey, setInvalidateAPIKey] = useState(0);
  const [recordPaymentPayload, setRecordPaymentPayload] = useState();
  const [openCreatePlanModal, setOpenCreatePlanModal] = useState(false);
  const [openEditPlanModal, setOpenEditPlanModal] = useState(false);
  const [openSellPlanModal, setOpenSellPlanModal] = useState(false);
  const [openRecordPaymentModal, setOpenRecordPaymentModal] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState();
  const [selectedMode, setSelectedMode] = useState("Plan");

  const initialTabsPlan = [
    { title: "All", value: "all" },
    { title: "Active", value: "published" },
    { title: "Inactive", value: "un_published" },
  ];
  const initialTabsCustomers = [
    { title: "All", value: "all" },
    { title: "Paid", value: "paid" },
    { title: "Not Paid", value: "requested" },
  ];
  const currentTabs =
    selectedMode === "Customers" ? initialTabsCustomers : initialTabsPlan;
  const initialTab =
    selectedMode === "Customers" ? initialTabsCustomers[0] : initialTabsPlan[0];
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const plansIsSelected = selectedMode === "Plan";
  const ApiClient = useApiManager();
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [totalPlanCount, setTotalPlanCount] = useState(0);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [planData, setPlanData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [status, setStatus] = useState({ link: "" });
  const [data, setData] = useState("");
  const [hideEmpty, setHideEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [planCount, setPlanCount] = useState([]);
  const [customerCount, setCustomerCount] = useState([]);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [planPagesCount, setPlanPagesCount] = useState(0);
  const [customerPagesCount, setCustomerPagesCount] = useState(1);
  // const [filterData, setFilterData] = useState({});
  const { filterData, setFilterData } = useFilter();
  const [clearFiltersData, setClearFilterData] = useState(false);

  const [planRecordCount, setPlanRecordCount] = useState({
    all: 0,
    published: 0,
    un_published: 0,
  });
  const [customerRecordCount, setCustomerRecordCount] = useState({
    all: 0,
    paid_count: 0,
    pending_count: 0,
  });
  // useEffect(() => {
  //   setFilterData({});
  // }, []);
  const currentRecordCount =
    selectedMode === "Customers" ? customerRecordCount : planRecordCount;
  useEffect(() => {
    getAdminProfiles();
  }, [
    status,
    currentPage,
    filterData,
    searchParam,
    selectedTab,
    selectedMode,
    invalidateAPIKey,
  ]);

  const getCounts = (countData) => {
    let newArr = Array.from(Object.keys(countData).reverse((a, b) => a - b));
    let newData = [];
    newArr.map((item) => {
      let newObj = countData[item];
      let title = `${item} (${countData[item].count || 0})`;
      title = titlizeString(title);
      newObj.title = title;
      newObj.link = countData[item].title || "";

      newData.push(newObj);
    });
    return newData.sort((a, b) => {
      return a.order - b.order;
    });
  };

  useEffect(() => {
    if (location?.state?.showAlert) {
      setIsShowAlert(location.state.showAlert);
    }
  }, [location?.state?.showAlert]);

  const createFilterParams = (data, defaultObj) => {
    let ignoreKeys = ["author_data", "admin_data", "date", "tags_data"];
    let filterParams = {};
    if (defaultObj) {
      filterParams = { ...filterParams, ...defaultObj };
    }
    genrageFilterParams(data, filterParams, ignoreKeys);
    return filterParams;
  };
  function getNumberOfPages(totalRecords, recordsPerPage = 10) {
    return Math.ceil(totalRecords / recordsPerPage);
  }
  const getAdminProfiles = () => {
    // let defaultObj = {
    //   status: status.link,
    //   page: currentPage,
    //   page_size: "20",
    // };

    setIsLoading(true);
    const getStatus = () => {
      if (selectedMode === "Customers") {
        return "published";
      } else {
        return selectedTab.value === "all" ? "" : selectedTab.value;
      }
    };
    const status = getStatus();

    ApiClient.getAllPlans({
      page: currentPage,
      searchParam: searchParam,
      status: status, //for customer mode plan list should only be published
    })
      .then((res) => {
        const delta = Math.round(res.data.count % 10) > 0 ? 1 : 0;
        // setTotalPages(Math.round(res.data.count / 10 + delta));
        const pageCount = getNumberOfPages(res.data.count);
        setPlanPagesCount(pageCount);
        // setData(res.data.results);
        setTotalPlanCount(res.data.count);
        setIsLoading(false);
        setHideEmpty(false);
        const allCount = res.data.all_count;
        const activeCount = res.data.active_count;
        const inactiveCount = res.data.inactive_count;
        const countData = {
          All: { count: allCount, title: "all", order: 1 },
          active: { count: activeCount, title: "published", order: 2 },
          inactive: { count: inactiveCount, title: "un_published", order: 3 },
        };
        const count = getCounts(countData);
        setPlanRecordCount({
          all: allCount,
          published: activeCount,
          un_published: inactiveCount,
        });

        setPlanCount(count);
        // setCountData(res.data);
        setPlanData(res.data.results);
      })
      .catch((err) => {
        console.log("All plans API error", err);
        setHideEmpty(false);
        setIsLoading(false);
      });

    ApiClient.getAllCustomers({
      page: currentPage,
      searchName: searchParam,
      searchPhone: searchParam,
      status: selectedTab.value === "all" ? "" : selectedTab.value,
    })
      .then((res) => {
        // const delta = Math.round(res.data.count % 10) > 0 ? 1 : 0;
        const pageCount = getNumberOfPages(res.data.count);
        setCustomerPagesCount(pageCount);
        setData(res.data.results);
        setTotalCustomerCount(res.data.count);
        const allCount = res.data.all_count;
        const paidCount = res.data.paid_count;
        const notPaidCount = res.data.pending_count;
        // const draftCount = res.data.pending_count;
        const countData = {
          All: { count: allCount, title: "All", order: 1 },
          paid: { count: paidCount, title: "Paid", order: 2 },
          "not Paid": { count: notPaidCount, title: "Not Paid", order: 3 },
          // draft: { count: draftCount, title: "Draft", order: 4 },
        };
        setCustomerRecordCount({
          all: allCount,
          paid: paidCount,
          requested: notPaidCount,
        });
        const count = getCounts(countData);
        setCustomerCount(count);
        // setCountData(res.data);
        setCustomerData(res.data.results);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const usersCount = selectedMode === "Customers" ? customerCount : planCount;
  // const sideBar = useMemo(() => {
  //   return <SideBar />;fs
  // }, []);
  const totalPages =
    selectedMode === "Customers" ? customerPagesCount : planPagesCount;
  const headerData =
    selectedMode === "Customers"
      ? [
          {
            title: "Sell Plan",
            icon: sellPlan,
            status: true,
            onClick: () => setOpenSellPlanModal(true),
          },
        ]
      : [
          {
            title: "Create Plan",
            icon: addIconWhite,
            status: true,
            onClick: () => setOpenCreatePlanModal(true),
          },
        ];

  const leftSideData = [
    // {
    //   title: "Filter",
    //   className: "themeColor boldFont",
    //   showBtn: true,
    //   icon: filter_alt,
    //   onClick: () => console.log("filter"),
    // },
  ];
  const totalRecordCount =
    selectedMode === "Customers" ? totalCustomerCount : totalPlanCount;
  const clearFilters = () => {
    setFilterData(new Object());
    setClearFilterData(!clearFiltersData);
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ["tag_ids", "tags_data", "category_ids", "author_ids"];

    if (checkObjectLength(filterData, ignoreKeys)) {
      return (
        <div className="mt-3">
          <FilterChips
            clearData={clearFilters}
            setData={setFilterData}
            data={filterData}
          />
        </div>
      );
    } else {
      if (Object.keys(filterData).length > 0) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);

  const onSearchChange = (e) => {
    setSearchParam(e);
  };
  const renderDashBoardHeader = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 1,
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Search..."
        onChange={(e) => onSearchChange(e.target.value)}
        sx={{ flex: 0.6 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
      <DashBoardHeader
        className="mt-2"
        style={{ marginRight: "2.5rem" }}
        leftData={leftSideData}
        data={headerData}
      />
    </div>
  );

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder="Search a patient by name or mobile number"
        searchMethod="PATIENTSEARCH"
        label="full_name"
        searchId="Patients"
        renderPath="/patient/{source_id}/dashboard" //ShowPatientProfile
        imagePath="profile_url"
      />
    );
  }, []);
  const renderModeSwitcher = () => {
    const styles = {
      container: (isSelected) => ({
        border: "1px solid #C8D2ED",
        backgroundColor: isSelected ? "#C8D2ED" : "transparent",
        color: isSelected ? "darkblue" : "inherit",
        borderRadius: "8px", // Adjust the radius as needed
        padding: "8px 16px", // Horizontal padding (left and right)
      }),
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          padding: "10px",
          paddingTop: "20px",
          paddingBottom: "20px",
          cursor: "pointer",
        }}
      >
        <div
          onClick={() => {
            setSelectedMode("Plan");
            setCurrentPage(1); //reset page
            setSelectedTab(initialTabsPlan[0]);
          }}
          style={styles.container(plansIsSelected)}
          className={plansIsSelected ? "active" : ""}
        >
          Plan
        </div>
        <div
          style={styles.container(!plansIsSelected)}
          onClick={() => {
            setSelectedMode("Customers");
            setCurrentPage(1); //reset page
            setSelectedTab(initialTabsPlan[0]);
          }}
          className={plansIsSelected ? "" : "active"}
        >
          Customers
        </div>
      </div>
    );
  };

  const showSales = () => (
    <div className="teamHeader stickyHeader">
      <div className="headerFiles">
        <AdminPanelHeader
          iconTitle="shopping_bag"
          title="Sales"
          searchBox={renderSearchBox}
        />
      </div>
      <div className="hrLine" />
      <div className="teamBody">
        {renderModeSwitcher()}
        {isShowAlert && (
          <div className="mt-3">
            <CommonAlert
              isShowAlert={isShowAlert}
              setIsShowAlert={setIsShowAlert}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            gap: 20,
            flex: 1,
            alignItems: "center",
          }}
        >
          <div style={{ marginLeft: 20 }}>
            <Tabs
              selected={selectedTab}
              setSelected={setSelectedTab}
              tabs={currentTabs}
              currentRecordCount={currentRecordCount}
              callback={() => {
                setCurrentPage(1);
              }}
            />
          </div>
          {renderFilterChips}
          {renderDashBoardHeader}
        </div>
      </div>
      <div className="hrLine" />
    </div>
  );

  const renderEmptyView = () => {
    return <EmptyView hide={isLoading || hideEmpty} title="No data found" />;
  };

  const showPlanRecords = (
    <PlanListing
      plans={planData}
      assignTeam
      setCurrentSelectedPlan={setCurrentSelectedPlan}
      setOpenEditPlanModal={setOpenEditPlanModal}
      setOpenSellPlanModal={setOpenSellPlanModal}
    />
  );

  const showCustomersRecords = (
    <CustomerListing customers={customerData} assignTeam />
  );
  const currentData = plansIsSelected ? planData : customerData;
  const currentListing = plansIsSelected
    ? showPlanRecords
    : showCustomersRecords;

  const salesBody = () => {
    return (
      <>
        {showSales()}
        <div className="usersList wrapperContent">
          {currentData?.length > 0 ? currentListing : renderEmptyView()}
          <div className="d-flex justify-content-end mb-4">
            <Pagination
              data={undefined}
              length={totalRecordCount}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </>
    );
  };

  const getFilterData = (data) => {
    setFilterData(data);
    setCurrentPage(1);
  };

  const showFilters = useCallback(() => {
    return (
      <FilterModal
        clearFiltersData={clearFiltersData}
        setClearFilterData={setClearFilterData}
        filter="patient"
        getData={getFilterData}
        data={filterData}
      />
    );
  }, [clearFiltersData, filterData]);

  return (
    <div>
      <ToastContainer />
      <div className="wrapper">
        {/* {sideBar} */}
        {salesBody()}
        <Loader show={isLoading} />
        {showFilters()}
      </div>
      <NewPlanDrawer
        isOpen={openCreatePlanModal}
        setIsOpen={setOpenCreatePlanModal}
        setInvalidateAPIKey={setInvalidateAPIKey}
      />
      {currentSelectedPlan && (
        <EditPlanDrawer
          isOpen={openEditPlanModal}
          setIsOpen={setOpenEditPlanModal}
          plan={currentSelectedPlan}
          setCurrentSelectedPlan={setCurrentSelectedPlan}
          setInvalidateAPIKey={setInvalidateAPIKey}
        />
      )}
      <SellPlanDrawer
        isOpen={openSellPlanModal}
        setIsOpen={setOpenSellPlanModal}
        setIsOpenRecordPayment={setOpenRecordPaymentModal}
        setRecordPaymentPayload={setRecordPaymentPayload}
        plans={planData?.map((item) => item.status === "published" && item)}
        currentSelectedPlan={currentSelectedPlan}
        setCurrentSelectedPlan={setCurrentSelectedPlan}
        setInvalidateAPIKey={setInvalidateAPIKey}
      />
      <RecordPaymentDrawer
        isOpen={openRecordPaymentModal}
        setIsOpen={setOpenRecordPaymentModal}
        recordPaymentPayload={recordPaymentPayload}
        closeDrawers={() => {
          setOpenSellPlanModal(false);
          setOpenRecordPaymentModal(false);
        }}
        setInvalidateAPIKey={setInvalidateAPIKey}
        setCurrentSelectedPlan={setCurrentSelectedPlan}
      />
    </div>
  );
}

export default Sales;
