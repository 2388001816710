import React, { useEffect, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { useNavigate, useParams } from 'react-router-dom';
import { CoreButton, Loader, QuestionsList } from '../index';
import { useDispatch } from 'react-redux';
import { setAlerts } from 'redux/Slices';
import { splitArrData, splitArrObjData } from '../Helper';

function QuestionsTab(props) {
  const { data } = props;
  const ApiClient = useApiManager();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputDataObj, setinputDataObj] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (data?.profile_info) {
      setinputDataObj([...data.profile_info]);
    }
  }, [data]);

  const submitQuestionAnswers = () => {
    let inputDataArr = inputDataObj.filter((item) => !!item && item);
    setIsLoading(true);

    ApiClient.createProfileAnswers(id, inputDataArr)
      .then((res) => {
        setIsLoading(false);
        setinputDataObj([]);
        navigate(`/patient/${id}/dashboard`);
        dispatch(setAlerts({ patient: { title: 'Patient Questions updated successfully', type: 'alert-success' } }));
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setErrors(err.response.data.errors);
      });
  };

  const renderProfileQuestin = () => {
    if (data?.profile_info) {
      return (
        <>
          <QuestionsList
            data={data.profile_info}
            inputDataObj={inputDataObj}
            setinputDataObj={setinputDataObj}
            errors={errors}
            hancleSave={submitQuestionAnswers}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className='w-100'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitQuestionAnswers();
        }}
      >
        {renderProfileQuestin()}
      </form>
      <Loader show={isLoading} />
    </div>
  );
}

export default QuestionsTab;
