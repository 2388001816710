import AllRoutes from "./routes/Routes";
import Routes from "./routes/Routes";
import { store } from "./redux/Store";
import { Provider } from "react-redux";
import React, { useCallback, useEffect, useMemo } from "react";
import { s3Url } from "whealth-core-web/components/Helper";
import "whealth-core-web/assets/style/main.css";
import "whealth-core-web/assets/style/main-style.scss";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { SideBar } from "components";
import { FilterProvider } from "whealth-core-web/components/FilterContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import mixpanel from "mixpanel-browser";

const RenderSideBar = () => {
  const { pathname } = useLocation();

  const returnSideBar = useMemo(() => <SideBar />, []);

  const sideBar = () => {
    const hideKeys = [["/article", "/details"]];
    const token = localStorage.getItem("token");

    let includedPath = false;
    hideKeys.map((item) => {
      if (typeof item == "string") {
        if (pathname.includes(item)) {
          includedPath = true;
        }
      } else if (Array.isArray(item)) {
        let newInclude = 0;
        item.map((item) => {
          if (pathname.includes(item)) {
            newInclude += 1;
          }
        });
        if (item.length == newInclude) {
          includedPath = true;
        }
      }
    });

    if (includedPath || !token) {
      return;
    }
    return returnSideBar;
  };

  return sideBar();
};

const App = () => {
  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "W Health Ventures";
    const favicon = document.getElementById("favicon");
    favicon.href = s3Url("icon-fav.svg", "/favicon.ico");
    const appleIcon = document.getElementById("apple-touch-icon");
    appleIcon.href = s3Url("apple-fav-icon.svg", "/logo192.png");

    const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, {
        debug: process.env.REACT_APP_ENVIRONMENT !== "production",
        track_pageview: true,
        persistence: "localStorage",
      });
    } else {
      console.warn("Mixpanel token not found in environment variables");
    }
  }, []);

  const ignorePath = ["/", ""];

  if (
    !localStorage.getItem("token") &&
    !ignorePath.includes(window.location.pathname)
  ) {
    window.location.pathname = "/";
  }

  // mixpanel.init('477e2491bf9e4d0503c7fb1d39a6e94f', { debug: true, track_pageview: true, persistence: 'localStorage' });

  return (
    <Provider store={store}>
      <Router>
        <FilterProvider>
          <DndProvider backend={HTML5Backend}>
            <RenderSideBar />
            <AllRoutes />
          </DndProvider>
        </FilterProvider>
      </Router>
    </Provider>
  );
};

export default App;
