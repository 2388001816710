import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import { filterFunnel, search } from 'res/images';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, exportToCsv, timeFormat } from '../Helper';
import Loader from '../Loader';
import Pagination from '../Pagination';
import CommonAlert from '../CommonAlert';
import RenderApolloTab from '../Apollo/RenderApolloTab';
import { coupons } from 'res/images';
import Coupons from './Coupons';
import { useNavigate, useLocation } from 'react-router-dom';
import { moreVert } from 'whealth-core-web/assets/images';
import { Popover, Whisper, Dropdown, Button, Modal } from 'rsuite';
import { useDispatch } from 'react-redux';
import { setManualOrderId, setPatientAddress, setManualPatient, setManualOrderSummary } from 'redux/Slices';
import { ToastContainer, toast } from 'react-toastify';
import { FilterModal, FilterChips } from 'whealth-core-web/components';
import { useFilter } from 'whealth-core-web/components/FilterContext';
import { checkObjectLength, genrageFilterParams } from 'whealth-core-web/components/Helper';

function Orders({ index }) {
  const [orderLoadingState, setOrderLoadingState] = useState(undefined);
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [alert, setAlert] = useState({});
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const [orderSummary, setOrderSummary] = useState([]);
  const [allcoupons, setCoupons] = useState();
  const [isEditMode, setEditMode] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const { filterData, setFilterData } = useFilter();
  const [clearFiltersData, setClearFilterData] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const value = useRef('');
  const headerData = [
    {
      title: 'Overview',
      link: 'Orders',
    },
  ];

  let defaultObj = {
    page: currentPage,
    status: '',
    date_range: '',
    source: '',
    min_price: '',
    max_price: '',
    search_str: searchStr,
  };

  useEffect(() => {
    setFilterData({});
  }, []);

  useEffect(() => {
    getFilteredOrders();
  }, [currentPage, filterData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterData]);

  useEffect(() => {
    if (location?.state?.paymentSuccess === true) {
      toast.success(`Payment recorded for Order ${location?.state?.recordedOrderId}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    } else if (location?.state?.paymentSuccess === false) {
      toast.error('Payment recording failed. Please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [location.state]);

  const downloadOrder = (orderId) => {
    ApiClient.downloadOrder(orderId, { responseType: 'arraybuffer' })
      .then((res) => {
        const imageUrl = res.data.image_url;
        fetch(imageUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `order_${orderId}.png`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error downloading file:', error);
          });
        // Open image in a new tab
        const newTab = window.open(imageUrl, '_blank');
        if (newTab) {
          newTab.focus();
        } else {
          console.error('Failed to open image in a new tab.');
        }
      })
      .catch((err) => {
        console.log('downloadOrder error: ', err);
      });
  };

  const getOrderSummary = (orderId) => {
    ApiClient.getOrderSummary(orderId)
      .then((res) => {
        setOrderSummary(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCoupons();
  }, []);

  const getAllCoupons = () => {
    ApiClient.getCoupons()
      .then((res) => {
        setCoupons(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyFilter = (data) => {
    setFilterData({ ...filterData, ...data });
  };

  useEffect(() => {
    if (!openDetailModal) searchOrders();
  }, [searchStr, openDetailModal]);

  const searchOrders = () => {
    setIsLoading(true);
    const storedFilterData = JSON.parse(sessionStorage.getItem('ordersFilterData'));
    let data = storedFilterData ? storedFilterData : filterData;
    let searchFilterDataParams = createFilterParams(data, defaultObj);
    //let params = { page: currentPage, search_str: searchStr,  };
    ApiClient.searchOrders(searchFilterDataParams)
      .then((res) => {
        // setIsLoading(false);
        if (value.current === searchFilterDataParams.search_str) {
          setTotalPages(res.headers['total-pages']);
          setOrderList(res.data);
        }
        setOrderSummary(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('getOrders', err.response);
      });
  };

  const createFilterParams = (filterData, defaultObj) => {
    let params = { ...defaultObj };

    if (filterData.order_status) {
      if (filterData.order_status.length > 1) {
        params.status = filterData.order_status.join() || '';
      } else {
        params.status = filterData.order_status[0] || '';
      }
    }

    if (filterData.date) {
      if (Array.isArray(filterData.date)) {
        params.date_range = '';
      } else {
        params.date_range = filterData.date;
      }
    }

    if (filterData.orderTypes) {
      params.source = filterData.orderTypes;
    }

    if (filterData.priceRange) {
      params.min_price = filterData?.priceRange?.[0];
      params.max_price = filterData?.priceRange?.[1];
    }

    return params;
  };

  const getFilteredOrders = () => {
    setIsLoading(true);
    const storedFilterData = JSON.parse(sessionStorage.getItem('ordersFilterData'));
    let data = storedFilterData ? storedFilterData : filterData;
    let params = createFilterParams(data, defaultObj);
    if (params.date_range) {
      params.date_range = String(params.date_range);
    }
    ApiClient.getFilteredOrders(params)
      .then((res) => {
        setOrderSummary(res.data);
        setTotalPages(res.headers['total-pages']);
        setOrderList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('getFilteredOrders err: ', err);
        setIsLoading(false);
      });
  };

  const exportCsv = () => {
    setIsLoading(true);
    ApiClient.exportOrderCsv()
      .then((res) => {
        exportToCsv(res.data, `Med_order_${dateFormat(new Date())}-${timeFormat(new Date())}.csv`);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('exportCsv', err.response);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className='w-100'>
        <CoreInputBox
          placeholder='Search an Order by Patent name or Order ID'
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='shopping_cart' title='Orders' searchBox={renderSearchBox()} />
      </div>
    );
  };

  const data = [
    ['Task', 'Hours per Day'],
    ['Partial', 84],
    ['Cancelled', 10],
    ['Pending', 58],
    ['Completed', 80],
  ];

  const options = {
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    className: 'chart-headerData',
    legend: { position: 'none' },
    colors: ['#F3D27E', '#BFBDBD', '#68AA52', '#D01C1C'],
  };

  const rightSideData = [
    {
      title: 'Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filterFunnel,
    },
  ];
  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={rightSideData} />;
  }, []);

  const handleViewOrder = (id, address, user) => {
    dispatch(setManualOrderId(id));
    dispatch(setPatientAddress(address));
    dispatch(setManualPatient(user));
  };

  const handleDetail = (index, orderDetail, orderStatus, patientName, patientId) => {
    const idStr = orderDetail?.order_id;
    const orderId = Number(idStr.substring(6));
    const userAddress = orderDetail?.user?.address;
    const user = orderDetail.user;
    handleViewOrder(orderId, userAddress, user);
    if (orderStatus === 'draft') {
      navigate(`/orders/${index}/${orderId}/${patientId}/${orderStatus}`, {
        state: { orderStatus, patientName, orderId },
      });
    } else {
      navigate(`/orders/${index}/${orderId}/${patientId}`);
    }
  };
  const renderSearchInput = () => {
    return (
      <div className='mb-3'>
        <CoreInputBox placeholder='Search ' leftIcon={search} />
      </div>
    );
  };

  const orderStatus = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
    {
      title: 'Partially Ordered',
      id: 'ordered_partially',
    },
    {
      title: 'Unfulfilled',
      id: 'in_progress',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
    {
      title: 'Draft',
      id: 'draft',
    },
    {
      title: 'Partially Confirmed',
      id: 'partially_confirmed',
    },
  ];

  const SortBy = () => (
    <Dropdown title='Sort By' className='sortBy-btn ms-3' placement='bottomEnd'>
      <Dropdown.Item>Newest to oldest</Dropdown.Item>
      <Dropdown.Item>Amt (High to Low)</Dropdown.Item>
      <Dropdown.Item>Amt (Low to High)</Dropdown.Item>
    </Dropdown>
  );

  const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => {
    return (
      <Popover ref={ref} {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
          <Dropdown.Item eventKey='1'>View Details</Dropdown.Item>
          <Dropdown.Item eventKey='2'>Download</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  });

  const renderDropdown = (index, item, patientName, patientId) => {
    const idStr = item?.order_id;
    const orderId = Number(idStr.substring(6));
    const handleSelect = (eventKey) => {
      if (eventKey === '1') {
        handleDetail(index, item, item.order_status, patientName, patientId);
      } else if (eventKey === '2') {
        setOrderId(orderId);
        downloadOrder(orderId);
      }
      dispatch(setManualOrderSummary(''));
    };

    return (
      <Whisper trigger='click' placement='auto' speaker={<MenuPopover onSelect={handleSelect} />}>
        <Button appearance='subtle'>
          <img height={12} src={moreVert} className='ml-10 me-1 pointer' alt='More options' />
        </Button>
      </Whisper>
    );
  };

  const getFilterData = (data) => {
    setFilterData(data);
    sessionStorage.setItem('ordersFilterData', JSON.stringify(data));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilterData(new Object());
    setClearFilterData(!clearFiltersData);
    sessionStorage.removeItem('ordersFilterData');
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ['tag_ids', 'tags_data', 'category_ids', 'author_ids'];
    if (checkObjectLength(filterData, ignoreKeys)) {
      return (
        <div className='mt-2 pt-1'>
          <FilterChips clearData={clearFilters} setData={setFilterData} data={filterData} />
        </div>
      );
    } else {
      if (Object.keys(filterData).length > 0) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);

  const showFilters = useCallback(() => {
    return (
      <FilterModal
        clearFiltersData={clearFiltersData}
        setClearFilterData={setClearFilterData}
        filter='orders'
        getData={getFilterData}
        data={filterData}
      />
    );
  }, [clearFiltersData, filterData]);

  const showOrders = () => (
    <div className='mb-4'>
      {imageUrl && (
        <>
          <img src={imageUrl} alt='Downloaded Order' />
          <a href={imageUrl} download={`order_${orderId}.png`}>
            Download Image
          </a>
        </>
      )}
      <div className='articleHeader stickyHeader'>
        {renderHeader()}
        <div className='hrLine mb-3' />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='d-flex justify-content-between mb-4'>
          <div className='d-flex gap-2'>
            <div
              onClick={() => {
                navigate('/manage-inventory');
              }}
              className='reusableBtnActive  reusableBtn '
              style={{
                background: '#EBEFFC',
                // border: '1px solid #133696',
                color: '#133696',
                margin: '0 5px',
              }}
            >
              Manage Inventory
            </div>
            <div
              onClick={() => {
                setEditMode(true);
              }}
              className='reusableBtnActive  reusableBtn '
              style={{
                background: '#EBEFFC',
                // border: '1px solid #133696',
                color: '#133696',
              }}
            >
              <img src={coupons} alt='Coupons' />
              View Coupons
            </div>
            <Modal
              backdrop='static'
              size='md'
              open={isEditMode}
              onClose={() => {
                setEditMode(false);
              }}
            >
              <Modal.Header>
                <div className='settingFormOutline p-0'>
                  <div className='formTitle mb-0'>Coupons</div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Coupons
                  activeCoupons={allcoupons?.results?.active_coupons}
                  expiredCoupons={allcoupons?.results?.expired_coupons}
                />
              </Modal.Body>
            </Modal>
            {renderFilterChips}
          </div>
          <div className='d-flex flex-row'>
            {renderDashBoardHeader}
            <div
              onClick={() => {
                exportCsv();
              }}
              className='reusableOutlineBtn reusableBtn ms-2'
            >
              Export
            </div>
            <div
              onClick={() => {
                navigate(`/orders/new-orders`);
                dispatch(setManualPatient(''));
                dispatch(setPatientAddress(''));
                dispatch(setManualOrderSummary(''));
              }}
              className='reusableBtnActive  reusableBtn ms-2'
              title='Click to create an order for a patient'
            >
              + Add Order
            </div>
          </div>
        </div>
        {alert.type == 'alert-success' && (
          <CommonAlert className='mt-3' setIsShowAlert={setAlert} isShowAlert={alert.message} alertType={alert.type} />
        )}
        <div className='table-responsive order-table'>
          <table class='table'>
            <thead>
              <tr>
                <th scope='col'>Order ID</th>
                <th scope='col'>Date</th>
                <th scope='col'>Patient</th>
                <th scope='col'>Address</th>
                <th scope='col'>Ph. Number</th>
                <th scope='col'>Amount</th>
                <th scope='col'>Source</th>
                <th scope='col'>Status</th>
                <th scope='col'>Details</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.length || isLoading ? (
                orderList.map((item, index) => {
                  return (
                    <tr key={item.order_id}>
                      <td>{item.order_id}</td>
                      <td width={120}>{dateFormat(item.created_at) + ' ' + timeFormat(item.created_at)}</td>
                      <td className='capitalize'>
                        <strong>{item?.user?.full_name}</strong>
                      </td>
                      <td width={300}>{item.shipping_address}</td>
                      <td>{item?.user?.phone}</td>
                      <td width={150}>₹ {parseFloat(JSON.parse(item.total_amount)?.toFixed(2))}</td>
                      <td width={150} className='text-capitalize'>
                        {item?.source}
                      </td>
                      <td>
                        <span className={`align-items-center badge capitalize active ${item.ehr_order_status}`}>
                          {orderStatus.find((item2) => item2.id == item.ehr_order_status)?.title}
                        </span>
                      </td>
                      <td>{renderDropdown(index, item, item?.user?.full_name, item?.user?.id)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className='text-center'>
                    {' '}
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex justify-content-end mb-4'>
        {(orderList?.length > 9 || currentPage > 1) && (
          <Pagination
            data={orderList}
            length={orderList?.count}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className='wrapper'>
        <Loader show={isLoading} />
        {!index ? (
          showOrders()
        ) : (
          <RenderApolloTab
            order={orderList}
            setOrderList={setOrderList}
            searchOrder={searchOrders}
            screenLoading={isLoading}
            orderLoadingState={orderLoadingState}
            setOrderLoadingState={setOrderLoadingState}
          />
        )}
        {showFilters()}
        <ToastContainer toastStyle={{ backgroundColor: '#EBF8E7' }} />
      </div>
    </>
  );
}

export default Orders;
