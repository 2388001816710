import React from 'react';

function CommonBodyCard(props) {
  const { label, icon, value, subvalue, items, defaultstart, marginnone, flexcolumn } = props;
  return (
    <div className={`checkup-entry w-100  align-items-center ${defaultstart} `}>
      {/* {items ? (
        <div className='checkupIcon'>
          <span class='material-icons-outlined'>{icon}</span>{' '}
        </div>
      ) : (
        <div className='checkupIcon'>
          <img src={icon} />
        </div>
      )} */}
      <div
        className={` checkup-colour1 capitalize justify-content-between w-100 ${defaultstart} ${flexcolumn}`}
      >
        <div>{label}</div>
        <div className={`${marginnone}`}>{value}</div>
        <div className={`${marginnone}`}>{subvalue}</div>
      </div>
    </div>
  );
}

export default CommonBodyCard;
