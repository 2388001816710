import useApiManager from "networking/ApiManager";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setAlerts } from "redux/Slices";
import { Button, Modal } from "rsuite";
import { CoreDropDown, Select2DropDown } from "whealth-core-web/components";
import labels from "../inputFields.json";

export const AddDepartmentModal = ({ open, handleClose, addDepartment }) => {
  const [departments, setDepartments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedDept, setSelectedDept] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [statusData, setStatusData] = useState([]);
  const [errorMsg, setErrorMsg] = useState({});
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedMode, setSelectedMode] = useState();
  const ApiClient = useApiManager();
  const patientDetails = useSelector(
    (state) => state?.sliceReducer?.patientDetails
  );
  let modes = labels?.consultantMode?.options || [];

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await ApiClient.searchDepartments();
        if (res.status == 200) {
          let updatedDepartments = [];
          res.data?.map((item) =>
            updatedDepartments.push({ ...item, title: item.name })
          );
          setDepartments(updatedDepartments);
          setStatusData();
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    };
    open && getData();
  }, [open]);

  const handleDeptChange = (value) => {
    departments.map((item) => {
      if (item.id == value) {
        setStatusData(item.department_status);
      }
    });
    setSelectedDept(value);
  };

  useEffect(() => {
    const getDoctors = async () => {
      try {
        const res = await ApiClient.getDoctors("", selectedDept);
        if (res.status == 200) {
          let doctors = [];
          res.data?.map((item) =>
            doctors.push({ ...item, title: item.full_name })
          );
          setDoctors(doctors);
        }
      } catch (e) {
        console.warn(e);
        toast.error(e?.response?.data?.message || e.message);
      }
    };
    open && getDoctors();
  }, [selectedDept]);

  const handleDoctorChange = (value) => {
    setSelectedDoctor(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleModeChange = (value) => {
    setSelectedMode(value);
  };

  const handleSave = async () => {
    // if (!selectedDept || !selectedDoctor || !selectedStatus) {
    //   toast.error('Please select all the fields!');
    // } else {
    const data = {
      selected_departments: [
        {
          department: selectedDept,
          admin: selectedDoctor,
          status: selectedStatus,
          consultation_mode: selectedMode,
        },
      ],
    };
    ApiClient.createDepartment(patientDetails?.id, data)
      .then((res) => {
        dispatch(
          setAlerts({
            patient: {
              title: "Department added successfully.",
              type: "alert-success",
            },
          })
        );
        addDepartment();
        handleCancelClick();
      })
      .catch((err) => {
        console.log(err.response.data);
        // toast.error(e.message);
        err?.response?.data?.errors?.selected_departments &&
          setErrorMsg(err?.response?.data?.errors?.selected_departments[0]);
      });
    // }
  };

  const handleCancelClick = () => {
    setSelectedDept(null);
    setSelectedDoctor();
    setSelectedStatus();
    setDoctors([]);
    setSelectedMode();
    handleClose();
    setErrorMsg({});
  };

  return (
    <>
      <ToastContainer />
      <Modal
        backdrop="static"
        keyboard={false}
        open={open}
        onClose={handleCancelClick}
      >
        <Modal.Header>
          <Modal.Title>Add Department</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column">
            <CoreDropDown
              placeholder="Select Department"
              label="Department"
              retuired={true}
              showMSG={errorMsg?.department}
              data={departments}
              labelStyle={{ fontWeight: "var(--lightFont)" }}
              setValue={(data) => {
                handleDeptChange(data);
              }}
              value={selectedDept}
              msgStyle={{ color: "red" }}
            />
            <Select2DropDown
              placement="auto"
              value={selectedDoctor}
              data={doctors}
              showMSG={errorMsg?.admin}
              labelKey="title"
              label={"Doctor"}
              placeholder={"Doctor"}
              valueKey="id"
              getObjData
              retuired
              onSelect={(_, selectedValue) => {
                handleDoctorChange(selectedValue?.id || "");
              }}
              // showMSG={''}
            />
            <CoreDropDown
              placeholder="Select Status"
              label="Status"
              retuired={true}
              data={statusData}
              showMSG={errorMsg?.status}
              labelStyle={{ fontWeight: "var(--lightFont)" }}
              setValue={(data) => {
                handleStatusChange(data);
              }}
              value={selectedStatus}
              msgStyle={{ color: "red" }}
            />
            <CoreDropDown
              placeholder={labels?.consultantMode?.label || "Consultant Mode"}
              label={labels?.consultantMode?.label || "Consultant Mode"}
              className="mt-2"
              retuired={true}
              showMSG={errorMsg?.consultation_mode}
              data={modes}
              labelStyle={{ fontWeight: "var(--lightFont)" }}
              setValue={(data) => {
                handleModeChange(data);
              }}
              value={selectedMode}
              msgStyle={{ color: "red" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSave()} appearance="primary">
            Save
          </Button>
          <Button onClick={() => handleCancelClick()} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
