import React, { useCallback, useState } from "react";
import "./drawer.scss"; // Create this for custom styles
import AddPlanForm from "whealth-core-web/forms/AddPlanForm";
import CloseIcon from "@mui/icons-material/Close";
import PausePlanForm from "scenes/Plans2Temp/PausePlanForm";

function PausePlanDrawer({
  isOpen,
  setIsOpen,
  setInvalidateAPIKey,
  planData,
  patientId,
}) {
  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <div className="drawerContainer">
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <button
          onClick={toggleDrawer}
          // className="closeDrawer"
          style={{ position: "absolute", right: 20, top: 20, zIndex: 1000 }}
        >
          <CloseIcon />
        </button>
        <PausePlanForm
          planData={planData}
          patientId={patientId}
          toggleDrawer={toggleDrawer}
        />
      </div>
      <div
        className={`overlay ${isOpen ? "active" : ""}`}
        onClick={toggleDrawer}
      ></div>
    </div>
  );
}

export default PausePlanDrawer;
