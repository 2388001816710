import React, { useEffect, useRef, useState } from "react";
import useApiManager from "networking/ApiManager";
import { useParams } from "react-router-dom";
import { nameLogo } from "res/images";

function ArticleView(props) {
  const ApiClient = useApiManager();
  const [data, setData] = useState({});
  const { id } = useParams();
  const contentRef = useRef();
  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const tokenStr = urlParams.get("token_str");

  useEffect(() => {
    if (tokenStr) {
      getArticlieDataPatient();
    } else {
      getArticlieData();
    }
  }, []);

  const getDate = (date) => {
    var str = date;
    var date = new Date(str);
    var day = date.getDate();
    var month = date.toLocaleString("default", { month: "short" });
    var year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    if (contentRef && data) {
      contentRef.current.innerHTML = data.content;
    }
  }, [data]);

  const getArticlieData = () => {
    setLoading(true);
    ApiClient.getSignleArticle(id)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.detail == "Not found.") {
          setData("");
        }
      });
  };

  const getArticlieDataPatient = () => {
    setLoading(true);
    ApiClient.getSignleArticlePatient(id)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.detail == "Not found.") {
          setData("");
        }
      });
  };

  const renderLogo = () => {
    return (
      <div>
        <img src={nameLogo} className="logoImage" />
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="flexDisplay">
        {data?.tags.map((item, index) => {
          return (
            <div key={index} className="tags">
              {item.title}
            </div>
          );
        })}
      </div>
    );
  };

  const renderArticleView = () => {
    return (
      <div>
        <div className="card mb-3">
          {data.image_url && (
            <img className="card-img-top articleImage" src={data.image_url} />
          )}
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="card-title">{data.title}</h2>
              <p className="card-text">
                <small className="text-muted">
                  Published on · {getDate(data.published_at)}{" "}
                </small>
              </p>
            </div>
            {data?.tags && renderTags()}
            <p ref={contentRef} className="card-text" />
          </div>
        </div>
      </div>
    );
  };

  const emptyView = () => {
    if (!loading) {
      return (
        <div className="emptyView">
          <h1>404</h1>
          <div> Page not found</div>
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <div className="articleViewPage">
      {renderLogo()}
      {data ? renderArticleView() : emptyView()}
    </div>
  );
}

export default ArticleView;
