import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
} from "@mui/material";
import useApiManager from "networking/ApiManager";
import { toast } from "react-toastify";
import { Banknote, CopyCheck } from "lucide-react";
const samplePayload = {
  name: "Sanjay Kumar",
  amount: 300,
  phone: 7544998412,
  email: "sanjay.kumar@revealhealthtech.com",
  payment_mode: "cash",
  plan: 2,
  emi: 1,
  plan_items: [
    {
      product_name: "cgm",
      product: 1453,
      quantity: 3,
    },
    {
      product_name: "Doctor Consultation",
      product: 1454,
      quantity: 3,
    },
  ],
};

const initialValue = {
  plan: "",
  amount: "",
  name: "",
  phone: "",
  user_notes: "",
  admin_notes: "",
  emi: false,
};
const SellPlanForm = ({
  plans,
  setOpenRecordPaymentModal,
  setRecordPaymentPayload,
  currentSelectedPlan,
  setInvalidateAPIKey,
  setCurrentSelectedPlan,
  closeDrawer,
}) => {
  const [paymentLink, setPaymentLink] = useState("");
  const ApiClient = useApiManager();
  const currentPlan = plans.find((plan) => plan.id === currentSelectedPlan?.id);
  const [formValues, setFormValues] = useState(initialValue);

  const checkIfFormValuesIsEmpty = () => {
    if (
      formValues.plan === "" ||
      formValues.amount === "" ||
      formValues.name === "" ||
      formValues.phone === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isFormFilled = !checkIfFormValuesIsEmpty();
  useEffect(() => {
    setFormValues({
      plan: currentPlan?.id || "",
      amount: currentPlan?.max_price || "",
      name: "",
      phone: "",
      user_notes: "",
      admin_notes: "",
      emi: false,
    });
  }, [currentSelectedPlan]);
  const planOptions = [
    { title: "Plan A", value: "planA" },
    { title: "Plan B", value: "planB" },
  ];
  const matchingPlan = plans?.find((plan) => {
    return plan.id === formValues.plan;
  });
  const minimumPrice = matchingPlan?.min_price;
  // const price = matchingPlan?.price

  const selectedPlanItems = plans
    ?.find((plan) => {
      return plan.id === formValues.plan;
    })
    ?.plan_items?.map((item) => ({
      id: item.id,
      product: item.product.id,
      quantity: item.quantity,
    }));

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Values:", formValues);
  };
  const createRazorPayPaymentReq = () => {
    const razorpayPayload = {
      name: formValues.name,
      amount: formValues.amount,
      phone: formValues.phone,
      payment_mode: "razorpay",
      plan: formValues.plan,
      emi: formValues.emi ? 1 : 0,
      plan_items: selectedPlanItems,
      admin_notes: formValues.admin_notes,
      user_notes: formValues.user_notes,
    };
    ApiClient.createPlanPaymentRequest(razorpayPayload)
      .then((res) => {
        console.log("createRazorPayPaymentReq", res);
        setPaymentLink(res.data.payment_link);
      })
      .catch((err) => {
        const errData = err.response.data;
        console.log("errData", errData);
        Object.values(errData).map((errMsg) => toast.error(errMsg[0]));
        console.log("createRazorPayPaymentReq error", err);
      });
  };
  const handleCopyPaymentLink = () => {
    navigator.clipboard
      .writeText(paymentLink)
      .then(() => {
        console.log("payment lin", paymentLink);
        toast.success("Payment link copied to clipboard!");
        closeDrawer();
        setFormValues(initialValue);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        mx: "auto",
        p: 3,
        overflowY: "scroll",
        height: "100%",
      }}
    >
      <Typography sx={{ mb: 3, textAlign: "center", fontSize: "1rem" }}>
        Sell Plan
      </Typography>

      {/* Plan Details Section */}
      <Typography sx={{ mb: 2, fontSize: "1rem" }}>Plan Details</Typography>
      <TextField
        label="Plan Name"
        name="plan"
        select
        fullWidth
        margin="normal"
        variant="outlined"
        value={formValues.plan}
        onChange={handleChange}
        required
        InputProps={{
          sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      >
        {plans.map((plan) => (
          <MenuItem key={plan.name} value={plan.id}>
            {plan.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Price"
        name="amount"
        type="number"
        fullWidth
        margin="normal"
        variant="outlined"
        value={formValues.amount}
        onChange={handleChange}
        required
        InputProps={{
          sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      />
      {minimumPrice && (
        <Typography sx={{ mb: 2 }}>Minimum - Rs. {minimumPrice}</Typography>
      )}
      {/* Customer Details Section */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Customer Details
      </Typography>
      <TextField
        label="Name"
        name="name"
        fullWidth
        margin="normal"
        variant="outlined"
        value={formValues.name}
        onChange={handleChange}
        required
        InputProps={{
          sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      />

      <TextField
        label="Phone Number"
        name="phone"
        fullWidth
        margin="normal"
        variant="outlined"
        value={formValues.phone}
        onChange={handleChange}
        required
        InputProps={{
          sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      />

      <TextField
        label="Customer Facing Notes"
        name="user_notes"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        variant="outlined"
        value={formValues.user_notes}
        onChange={handleChange}
        InputProps={{
          sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      />

      <TextField
        label="Internal Notes"
        name="admin_notes"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        variant="outlined"
        value={formValues.admin_notes}
        onChange={handleChange}
        InputProps={{
          sx: { fontSize: "0.875rem" }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: "0.75rem" },
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            name="emi"
            checked={formValues.emi}
            onChange={handleChange}
          />
        }
        sx={{ fontSize: "0.75rem" }}
        label="Enable No Cost EMI"
      />

      {/* Action Buttons */}
      <Box sx={{ display: "flex", gap: 2, mt: 3, justifyContent: "center" }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ fontSize: "0.75rem" }}
          type="submit"
          onClick={() => {
            if (isFormFilled) {
              const recordPaymentPayload = {
                name: formValues.name,
                amount: formValues.amount,
                phone: formValues.phone,
                payment_mode: "cash",
                plan: formValues.plan,
                emi: formValues.emi ? 1 : 0,
                plan_items: selectedPlanItems,
              };
              setRecordPaymentPayload(recordPaymentPayload);
              setOpenRecordPaymentModal(true);
              setFormValues(initialValue);
            } else {
              toast.error("Please fill all the required fields");
            }
          }}
        >
          Record Payment
        </Button>
        {!paymentLink ? (
          <Button
            variant="outlined"
            color="primary"
            sx={{
              fontSize: "0.75rem",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
            type="submit"
            onClick={() => {
              if (isFormFilled) {
                createRazorPayPaymentReq();
              } else {
                toast.error("Please fill all the required fields");
              }
            }}
          >
            <Banknote />
            Generate Payment Link
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: "0.75rem",
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
            onClick={handleCopyPaymentLink}
          >
            <CopyCheck />
            Copy Payment Link
          </Button>
        )}
      </Box>

      {/* <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, fontSize: "0.75rem" }}
      >
        Submit
      </Button> */}
    </Box>
  );
};

export default SellPlanForm;
