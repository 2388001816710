import useApiManager from "networking/ApiManager";
import React, { useEffect, useState } from "react";
import { json } from "react-router-dom";
import PlanTile from "./PlanTile";
import { Loader, Pagination } from "whealth-core-web/components";
import { ToastContainer } from "react-toastify";

function getNumberOfPages(totalRecords, recordsPerPage = 10) {
  return Math.ceil(totalRecords / recordsPerPage);
}

const PatientPlanDetails = ({ patientId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const ApiClient = useApiManager();
  const [plans, setPlans] = useState();
  const planResults = plans?.results || [];
  const activePlans = planResults.filter((plan) => plan.status === "active");
  const upcomingPlans = planResults.filter(
    (plan) => plan.status === "upcoming"
  );
  const inActivePlans = planResults.filter(
    (plan) => plan.status === "inactive"
  );

  const totalRecordCount = planResults.length;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = getNumberOfPages(plans?.count || 1);
  useEffect(() => {
    setIsLoading(true);
    ApiClient.getPlanInfoForPatient(patientId)
      .then((res) => {
        setPlans(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const renderUpcomingPlans = () => {
    return (
      <div>
        <div className="py-4">Upcoming Plans</div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {upcomingPlans?.map((plan, index) => {
            return (
              <div key={index}>
                <PlanTile
                  planData={plan}
                  patientId={patientId}
                  showPauseAndExtension={false}
                />
              </div>
            );
          })}
          {upcomingPlans?.length == 0 && <div>No Plans Found</div>}
        </div>
      </div>
    );
  };
  const renderActivePlans = () => {
    return (
      <div>
        <div className="py-4">Active Plans</div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {activePlans?.map((plan, index) => {
            return (
              <div key={index}>
                <PlanTile
                  planData={plan}
                  showPauseAndExtension
                  patientId={patientId}
                />
              </div>
            );
          })}
          {activePlans?.length == 0 && <div>No Plans Found</div>}
        </div>
      </div>
    );
  };
  const renderPastPlans = () => {
    return (
      <div className="mb-4">
        <div className="py-4">History</div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {inActivePlans?.map((plan, index) => {
            return (
              <div key={index}>
                <PlanTile
                  planData={plan}
                  patientId={patientId}
                  showPauseAndExtension={false}
                />
              </div>
            );
          })}
          {inActivePlans?.length == 0 && <div>No Plans Found</div>}
        </div>
      </div>
    );
  };
  return (
    <div style={{ backgroundColor: "transparent" }} className="wrapper">
      <ToastContainer />
      <Loader show={isLoading} />
      {renderUpcomingPlans()}
      {renderActivePlans()}
      {renderPastPlans()}
      <div className="d-flex justify-content-end mb-4">
        <Pagination
          data={undefined}
          length={totalRecordCount}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PatientPlanDetails;
