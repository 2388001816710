import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";

import { WhatsApp, MoreVert, Call } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "whealth-core-web/components/Helper";
import EditIcon from "@mui/icons-material/Edit";
import useApiManager from "networking/ApiManager";
import { planKeyMap } from "scenes/Sales";
import { HandCoins, Pencil } from "lucide-react";
const calculateWeightChange = (patient) => {
  const currentWeight = parseFloat(patient.health_matrix.current_weight);
  const startingWeight = parseFloat(patient.starting_weight);
  if (isNaN(currentWeight) || isNaN(startingWeight)) return "";
  return (currentWeight - startingWeight).toFixed(1);
};

const calculateTimeSinceLastWeightLog = (patient) => {
  const lastWeightLog = new Date(patient.last_weight_log_activity);
  const now = new Date();
  const diffHours = Math.floor((now - lastWeightLog) / (1000 * 60 * 60));
  return `${diffHours} Hours ago`;
};

const transformPatientData = (patient) => ({
  ...patient,
  language: patient.health_matrix.language || "",
  personality: patient.health_matrix.personality || patient.personality || "",
  program: patient.health_matrix.program || patient.program || "",
  weight: patient.weight || "",
  weight_change: calculateWeightChange(patient),
  weight_change_time: calculateTimeSinceLastWeightLog(patient),
  plan_status: patient.onboarding_status || "",
  call_status: patient.health_matrix.call_status || "N/A",
  lab_status: patient.health_matrix.lab_status || "",
});

const PlanListing = ({
  plans,
  setCurrentSelectedPlan,
  setOpenEditPlanModal,
  setOpenSellPlanModal,
}) => {
  const navigate = useNavigate();
  const handleRowClick = (record) => {
    navigate(`/patient/${record.id}/dashboard`, { state: { data: record } });
  };

  const columns = [
    {
      title: "Plan Name",
      render: (record) => (
        <div
          className="flex items-center space-x-3"
          style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}
        >
          <div
            className="flex-grow min-w-0"
            style={{ flexGrow: 1, minWidth: 0 }}
          >
            <div
              className="font-medium truncate"
              style={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.name}
              <span style={{ fontSize: "10px", marginLeft: "4px" }}></span>
            </div>

            <div
              style={{
                fontSize: "0.75rem",
                color: "#6B7280",
                display: "flex",
                alignItems: "center",
              }}
            >
              {record?.updated_at && (
                <div className="bottomTextView">
                  {dateFormat(record?.updated_at)}
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Type",
      render: (record) => <div className="capitalize">{record.type || ""}</div>,
    },
    // { title: 'City', field: 'city' },
    {
      title: "Description",
      render: (record) => <div>{record.description || ""}</div>,
    },
    {
      title: "Duration",
      render: (record) => <div>{record.plan_duration || ""} Days</div>,
    },
    {
      title: "Price",
      render: (record) => (
        <div>
          <div>Rs. {record.max_price || ""}</div>
          <div style={{ color: "#166534" }}>
            Min - Rs. {record.min_price || "Min - "}
          </div>
        </div>
      ),
    },
    {
      title: "Service",
      render: (record) => (
        <div className="text-sm">
          {record?.plan_items?.map((service, index) => {
            return (
              <div key={index}>
                {service.product.product_name} ({service.quantity})
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: "Status",
      render: (record) => {
        //   const isActive = record.plan_status.toLowerCase() === "active";
        const isActive = record.status.toLowerCase() === "published";
        return (
          <div
            style={{ display: "flex", justifyContent: "space-between", gap: 4 }}
          >
            <Chip
              label={planKeyMap[record.status]}
              size="small"
              sx={{
                backgroundColor: isActive ? "#F0FDF4" : "#EBF0FF",
                color: isActive ? "#166534" : "#243B86",
                borderRadius: "16px",
                padding: "2px 8px",
                "& .MuiChip-label": {
                  padding: 0,
                },
              }}
            />
            <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
              {isActive && (
                <HandCoins
                  size={30}
                  color="white"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#243B86",
                    borderRadius: "10%",
                    padding: "5px",
                  }}
                  onClick={() => {
                    setCurrentSelectedPlan({
                      ...record,
                      // plan_items_pre_manipulation: record.plan_items,
                      plan_items: record.plan_items?.map((item) => ({
                        ...item,
                        product: item.product.id,
                        product_name: item.product.product_name,
                        quantity: item.quantity,
                      })),
                    });
                    setOpenSellPlanModal(true);
                  }}
                />
              )}
              <Pencil
                size={30}
                color="#243B86"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#D1DCFF",
                  borderRadius: "10%",
                  padding: "5px",
                }}
                onClick={() => {
                  setOpenEditPlanModal(true);
                  setCurrentSelectedPlan({
                    ...record,
                    // plan_items_pre_manipulation: record.plan_items,
                    plan_items: record.plan_items?.map((item) => ({
                      ...item,
                      product: item.product.id,
                      product_name: item.product.product_name,
                      quantity: item.quantity,
                    })),
                  });
                }}
              />
            </div>
          </div>
        );
      },
    },
    // { title: 'Call Status', field: 'call_status' },

    // {
    //   title: '',
    //   render: () => (
    //     <IconButton size='small'>
    //       <MoreVert className='text-gray-400' />
    //     </IconButton>
    //   ),
    // },
  ];

  const transformedPlans = plans;

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <TableContainer component={Paper} className="overflow-x-auto">
        <Table className="min-w-full">
          <TableHead style={{ background: "#ebf0ff", fontWeight: "500" }}>
            <TableRow className="bg-gray-100">
              {columns.map((column, index) => (
                <TableCell key={index} className="font-medium text-gray-700">
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedPlans.map((plan) => (
              <TableRow
                key={plan.id}
                // onClick={() => handleRowClick(plan)}
                className="hover:bg-gray-100 cursor-pointer cursor transition-colors duration-150"
                style={{ transition: "background-color 0.15s ease-in-out" }}
              >
                {columns.map((column, index) => (
                  <TableCell key={index}>
                    {column.render ? column.render(plan) : plan[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PlanListing;
