export const getColor = (status) => {
  switch (status) {
    case "active":
      return { bgColor: "#E1F8DF", textColor: "#166534" };
    case "inactive":
      return { bgColor: "#E5E7EB", textColor: "#333333" };
    case "upcoming":
      return { bgColor: "#243B86", textColor: "#fff" };
    case "paused":
      return { bgColor: "#FFE8A3", textColor: "#31101D" };
    default:
      return { bgColor: "red", textColor: "white" };
  }
};
