import React from "react";
import { getColor } from "./planUtils";

const PlanTileMini = ({ planData }) => {
  const status = planData?.status;
  const { bgColor, textColor } = getColor(status);
  const [startDate, endDate] = [planData.start_date, planData.end_date];
  const planName = planData.plan.name;
  const planType = planData.plan.type;
  const planDuration = Math.round(planData.plan.plan_duration / 30);
  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#F1F5FF",
        width: "max-content",
        gap: "0.5rem",
        paddingInline: "0.5rem",
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
        borderTopLeftRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
        borderBottomRightRadius: "0.5rem",
        color: "#243B86",
        fontWeight: 500,
      }}
    >
      <p
        className="capitalize"
        style={{
          paddingInline: "0.5rem",
          backgroundColor: bgColor,
          color: textColor,
          padding: "0.25rem",
          borderRadius: "0.5rem",
        }}
      >
        {status}
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.10rem",
          fontWeight: "normal",
          color: "#333",
        }}
      >
        <span style={{ color: "#333333" }}>{startDate}</span>
        <span>-</span>
        <span style={{ color: "#333333" }}>{endDate}</span>
      </div>
    </div>
  );

  const body = (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ fontSize: "1rem", color: "#243B86", fontWeight: "600" }}>
          {`${planName} |
          ${planType[0].toUpperCase() + planType.slice(1)} |
          ${planDuration} Months`}
        </div>
      </div>
      <div style={{ color: "#333" }}>
        <div>1 pause (1 month) | 1 extention (1 month)</div>
      </div>
    </div>
  );

  // const footer = (
  //   <div style={{ color: "#757575" }}>
  //     Total pause allowed: 3 (maximum of thirty days each)
  //   </div>
  // );
  return (
    <div
      style={{
        border: "1px solid #E1E1E1",
        borderRadius: ".5rem",
        fontSize: "0.75rem",
      }}
    >
      {header}
      <div
        style={{
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        {body}
        {/* {footer} */}
      </div>
    </div>
  );
};

export default PlanTileMini;
